import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TabPanel, {a11yProps} from "../global/TabPanel";
import FuelBoostPumpComponent from "../fuelBoostPump/FuelBoostPumpComponent";

export default function LavVacuumBlowerTest({
                                          isDashboard,
                                          nodeId,
                                          projectName,
                                          graphType,
                                          setGraphType,
                                          setCollectionTime,
                                          setTabId,
                                          tabId = 0
                                          }) {

    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={tabId}
                    onChange={handleChange}
                    aria-label="Collection Time"
                    textColor="primary"
                    indicatorColor="secondary"
                    variant="scrollable">
                    orientation="vertical"
                    <Tab
                        icon={<FamilyRestroomIcon/>}
                        label="Lav Vacuum Blower Test" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={tabId} index={0}>
                <FuelBoostPumpComponent
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    graphType={graphType}
                    setGraphType={setGraphType}
                />
            </TabPanel>
        </Box>
    );
}
