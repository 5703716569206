import * as React from 'react';
import ComponentTabPanel from "./ComponentTabPanel";
import {useEffect} from "react";
import { useDispatch } from "react-redux";
import {nodeActions} from "../../store";
import CACHealthTest from "./CACHealthTest";
import TefVibrationTest from "./TefVibrationTest";
import FlagAsymmetricTest from "./FlagAsymmetricTest";
import FuelBoostPumpTest from "./FuelBoostPumpTest";
import LavVacuumBlowerTest from "./LavVacuumBlowerTest";
import EngineDrivenPumpTest from "./EngineDrivenPumpTest";

export default function DetailWindow({
                                         isDashboard,
                                         nodeId,
                                         nodeType,
                                         projectName,
                                         graphType,
                                         setGraphType,
                                         setCollectionTime,
                                         setImageId
                                     }) {
    const dispatch = useDispatch();
    const [node, setNode] = React.useState(null);
    const [tabId, setTabId] = React.useState(0);
    const getNodeId = (nodeId) => (nodeId ? nodeId.substring(2) : nodeId);

    useEffect(() => {
        const t = getNodeId(nodeId);
        dispatch(
            nodeActions.getNodeStatusList({
                projectName: projectName,
                nodeId: t,
                nodeType: nodeType,
                pageable: {}
            })
        ).then((state) => {
            if (state.payload) {
                setNode(state.payload);
            }
        });
    }, [
        nodeId,
        nodeType,
        projectName,
        dispatch,
    ]);

    if (node != null) {
        switch (node.testType) {
            case 'CACHealth':
                return (<CACHealthTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);
            case 'FlagAsymmetry':
                return (<FlagAsymmetricTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);

            case 'TefVibration':
                return (<TefVibrationTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);
            case 'FuelBoostPump':
                return (<FuelBoostPumpTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);
            case 'LavVacuumBlower':
                return (<LavVacuumBlowerTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);
            case 'EngineDrivenPump':
                return (<EngineDrivenPumpTest
                    graphType={graphType}
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    node={node}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    setImageId={setImageId}
                    setTabId={setTabId}
                    tabId={tabId}
                />);
            default:
                return (
                    <ComponentTabPanel
                        graphType={graphType}
                        isDashboard={isDashboard}
                        nodeId={nodeId}
                        node={node}
                        projectName={projectName}
                        setCollectionTime={setCollectionTime}
                        setGraphType={setGraphType}
                        setImageId={setImageId}
                        setTabId={setTabId}
                        tabId={tabId}
                    />
                );
        }
    }
}
